// Here you can add other styles
.form-buttons {
    margin-top: 10px;
}
.form-buttons button.btn {
    margin-right: 4px;
}

.custom-feedback .invalid-feedback {
    display: block;
}

.add-order .accordion {
    margin-top: 10px;
}

.order-maintenances {
    .col-md-4 {
        margin-top: 10px;
    }
}

.addorder-buttons .btn {
    margin-right: 10px;
}

.action-buttons {
    display: flex;
    align-items: flex-end;
}
.filter-box {
    margin-bottom: 10px;
    .filter-buttons {
        margin-top: 10px;
        
        .btn {
            margin-right: 10px;
        }
    }
}